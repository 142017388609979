var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments p-4"},[_c('header',{staticClass:"payments__header"},[_c('div',{staticClass:"payments__header--search"},[_c('v-autocomplete',{attrs:{"items":_vm.shops,"loading":_vm.shopsLoading,"search-input":_vm.searchShops,"item-text":"name","label":_vm.$t('common.shops'),"disabled":false,"return-object":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchShops=$event},"update:search-input":function($event){_vm.searchShops=$event},"change":function($event){return _vm.getShopTransactions()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("shops")])])],1)]},proxy:true},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.address)}})],1)]]}}]),model:{value:(_vm.selectedShop),callback:function ($$v) {_vm.selectedShop=$$v},expression:"selectedShop"}})],1),_c('div',{staticClass:"d-flex  align-items-center ml-10"},[_c('div',{staticClass:"datepicker"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('date.from'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateFromMenu = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('div',{staticClass:"datepicker"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('date.to'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateToMenu = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('v-btn',{staticClass:"ml-10",attrs:{"color":"primary","disabled":_vm.transactionsLoading || (!_vm.dateFrom && !_vm.dateTo)},on:{"click":function($event){$event.stopPropagation();return _vm.getShopTransactions()}}},[_vm._v(" Apply ")])],1)]),_c('div',{staticClass:"payments__table"},[_c('div',{staticClass:"payments__table-header"},[_c('h3',{staticClass:"title"},[_vm._v("Transactions:")]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","disabled":!_vm.selectedCount},on:{"click":_vm.openDialog}},[_vm._v(" Calculate & Pay ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.shopTransactions,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
        showFirstLastPage: true
      },"loading":_vm.transactionsLoading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("shop")])])],1)]},proxy:true},{key:"item.selected",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.grossTotal",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.grossTotal)+" ")]}},{key:"item.transactions",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.transactions)+" ")]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Shop Transactions")])]),_c('v-card-text',[_c('div',{staticClass:"shop-transactions"},[_c('div',{staticClass:"pb-2"},[_vm._v("Selected transactions ("+_vm._s(_vm.selectedCount)+"):")]),_c('v-list',{staticClass:"shop-transactions__list overflow-y-auto",attrs:{"dense":""}},_vm._l((_vm.selectedShopTransactions),function(transaction){return _c('v-list-item',{key:transaction.orderNumber,staticClass:"shop-transactions__item"},[_c('v-list-item-title',{staticClass:"body-1",staticStyle:{"min-height":"30px"}},[_vm._v(" - "+_vm._s(transaction.itemName)+" "),_c('span',{staticClass:"pl-1"},[_vm._v("("+_vm._s(transaction.finalPrice)+" €)")])])],1)}),1)],1),_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"commission"},[_c('v-text-field',{attrs:{"type":"number","label":'Commission'}})],1),_c('div',[_c('div',{staticClass:"transactions-date"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('common.transactionDate'),"append-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.shopTransactionsDate),callback:function ($$v) {_vm.shopTransactionsDate=$$v},expression:"shopTransactionsDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.shopTransactionsDateMenu),callback:function ($$v) {_vm.shopTransactionsDateMenu=$$v},expression:"shopTransactionsDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.shopTransactionsDateMenu = false}},model:{value:(_vm.shopTransactionsDate),callback:function ($$v) {_vm.shopTransactionsDate=$$v},expression:"shopTransactionsDate"}})],1)],1)])]),_c('div',{staticClass:"body-1 black--text font-weight-medium"},[_vm._v(" Total Shop transactions: "+_vm._s(_vm.selectedShopTransactionsTotal)+" € ")])]),_c('v-card-actions',{staticClass:"px-4 pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }